export const BooznoLogo = () => (
  <svg
    width="183"
    height="34"
    viewBox="0 0 183 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29545 0C1.92314 0 0 1.92314 0 4.29546V20.6686V34H8.59091V33.9998H13.759C13.8729 33.9998 13.9859 33.9953 14.0976 33.9865C21.2761 33.6777 27 27.8332 27 20.6686C27 13.3062 20.9558 7.33789 13.5 7.33789C11.7681 7.33789 10.1124 7.65992 8.59091 8.24666V4.29546C8.59091 1.92314 6.66777 0 4.29545 0ZM8.59091 20.8058V20.7312C8.61122 18.2013 10.6944 16.1566 13.2612 16.1566C15.8406 16.1566 17.9317 18.2215 17.9317 20.7685C17.9317 23.3156 15.8406 25.3804 13.2612 25.3804C10.6944 25.3804 8.61122 23.3357 8.59091 20.8058Z"
      fill="#7D63EC"
    />
    <g clipPath="url(#clip0_6_600)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29545 0C1.92314 0 0 1.92314 0 4.29546V20.6686V34H8.59091V33.0905C3.56233 31.1513 0 26.3208 0 20.6686C0 15.0164 3.56233 10.1858 8.59091 8.24666V4.29546C8.59091 1.92314 6.66777 0 4.29545 0Z"
        fill="#5642C3"
      />
    </g>
    <g clipPath="url(#clip1_6_600)">
      <path
        d="M58.2041 22.8531C58.2041 20.95 57.5232 19.3188 56.1159 17.9141C54.754 16.5547 53.0743 15.8297 51.1676 15.8297C49.261 15.8297 47.5813 16.5094 46.2194 17.9141C44.8575 19.2734 44.1765 20.9047 44.1765 22.8531C44.1765 24.8016 44.8575 26.4328 46.2194 27.7922C47.5813 29.1516 49.261 29.8312 51.1676 29.8312C53.0743 29.8312 54.7086 29.1516 56.1159 27.7922C57.5232 26.3875 58.2041 24.7563 58.2041 22.8531ZM62.426 22.8531C62.426 25.9344 61.3365 28.5625 59.1121 30.7375C56.933 32.9125 54.3 34 51.213 34C48.126 34 45.493 32.9125 43.2686 30.7375C41.0895 28.5625 40 25.9344 40 22.8531V5H44.2219V14.6063C46.3102 12.6125 48.7616 11.6156 51.5762 11.6156C54.5724 11.6156 57.16 12.7031 59.2483 14.9234C61.3819 17.1891 62.426 19.8172 62.426 22.8531Z"
        fill="#7D63EC"
      />
      <path
        d="M83.4898 22.8533C83.4898 20.9502 82.8088 19.3189 81.4015 17.9143C80.0396 16.5549 78.3599 15.8299 76.4533 15.8299C74.5466 15.8299 72.8669 16.5096 71.505 17.9143C70.1431 19.2736 69.4621 20.9049 69.4621 22.8533C69.4621 24.8018 70.1431 26.433 71.505 27.7924C72.8669 29.1518 74.5466 29.8314 76.4533 29.8314C78.3599 29.8314 80.0396 29.1518 81.4015 27.7924C82.7634 26.3877 83.4898 24.7564 83.4898 22.8533ZM87.6663 22.8533C87.6663 25.9346 86.5767 28.5627 84.3523 30.7377C82.1733 32.9127 79.5402 34.0002 76.4533 34.0002C73.3663 34.0002 70.7333 32.9127 68.5088 30.7377C66.3298 28.5627 65.2402 25.9346 65.2402 22.8533C65.2402 19.7721 66.3298 17.1439 68.5088 14.9689C70.7333 12.7486 73.3663 11.6611 76.4533 11.6611C79.5402 11.6611 82.1733 12.7486 84.3977 14.9689C86.5767 17.1439 87.6663 19.7721 87.6663 22.8533Z"
        fill="#7D63EC"
      />
      <path
        d="M108.73 22.8533C108.73 20.9502 108.05 19.3189 106.642 17.9143C105.28 16.5549 103.601 15.8299 101.694 15.8299C99.7873 15.8299 98.1076 16.5096 96.7457 17.9143C95.3838 19.2736 94.7028 20.9049 94.7028 22.8533C94.7028 24.8018 95.3838 26.433 96.7457 27.7924C98.1076 29.1518 99.7419 29.8314 101.694 29.8314C103.646 29.8314 105.28 29.1518 106.642 27.7924C108.004 26.3877 108.73 24.7564 108.73 22.8533ZM112.907 22.8533C112.907 25.9346 111.817 28.5627 109.593 30.7377C107.369 32.9127 104.736 34.0002 101.649 34.0002C98.5616 34.0002 95.9286 32.9127 93.7041 30.7377C91.5251 28.5627 90.4355 25.9346 90.4355 22.8533C90.4355 19.7721 91.5251 17.1439 93.7041 14.9689C95.9286 12.7486 98.5616 11.6611 101.649 11.6611C104.736 11.6611 107.369 12.7486 109.593 14.9689C111.817 17.1439 112.907 19.7721 112.907 22.8533Z"
        fill="#7D63EC"
      />
      <path
        d="M123.167 29.4691H134.289V33.6379H115.495L126.89 16.1926H115.767V11.9785H134.607L123.167 29.4691Z"
        fill="#7D63EC"
      />
      <path
        d="M152.675 33.6371V21.0855C152.675 19.6809 152.176 18.4121 151.131 17.4152C150.087 16.373 148.862 15.8746 147.454 15.8746C146.047 15.8746 144.776 16.373 143.777 17.4152C142.778 18.4574 142.234 19.6809 142.234 21.0855V33.6371H138.012V21.0855C138.012 18.5934 138.965 16.373 140.872 14.4699C142.778 12.5668 145.003 11.6152 147.5 11.6152C149.996 11.6152 152.176 12.5668 154.082 14.4699C155.989 16.373 156.942 18.5934 156.942 21.0855V33.6371H152.675Z"
        fill="#7D63EC"
      />
      <path
        d="M178.778 22.8533C178.778 20.9502 178.097 19.3189 176.69 17.9143C175.328 16.5549 173.648 15.8299 171.741 15.8299C169.835 15.8299 168.155 16.5096 166.793 17.9143C165.431 19.2736 164.75 20.9049 164.75 22.8533C164.75 24.8018 165.431 26.433 166.793 27.7924C168.155 29.1518 169.835 29.8314 171.741 29.8314C173.693 29.8314 175.328 29.1518 176.69 27.7924C178.097 26.3877 178.778 24.7564 178.778 22.8533ZM183 22.8533C183 25.9346 181.91 28.5627 179.686 30.7377C177.461 32.9127 174.828 34.0002 171.741 34.0002C168.654 34.0002 166.021 32.9127 163.797 30.7377C161.618 28.5627 160.528 25.9346 160.528 22.8533C160.528 19.7721 161.618 17.1439 163.797 14.9689C166.021 12.7486 168.654 11.6611 171.741 11.6611C174.828 11.6611 177.461 12.7486 179.686 14.9689C181.91 17.1439 183 19.7721 183 22.8533Z"
        fill="#7D63EC"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_600">
        <rect width="8.59091" height="21.0733" fill="white" />
      </clipPath>
      <clipPath id="clip1_6_600">
        <rect
          width="143"
          height="29"
          fill="white"
          transform="translate(40 5)"
        />
      </clipPath>
    </defs>
  </svg>
);
