import React, { useState } from "react";
import "./loginForm.css";

const VerifyEmailMobile = () => {
  const [isChecked, setIsChecked] = useState(false);
  const handleChangeCheckbox = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <div className="login-form-wrapper">
      <div className="login-form">
        <h4 className="welcome-text">Welcome to Boozno</h4>
        <h3 className="mb-3">Verify your Email & Mobile no.</h3>
        <p className="description mb-5">
          Contact admin to change your mobile number or email address.
        </p>
        <div className="form-input-wrapper">
          <div className="mb-4">
            <label htmlFor="" className="form-label mb-1">
              Mobile Number
            </label>
            <div className="mb-3">+91 8956758695</div>
            <label htmlFor="" className="form-label mb-1">
              Email Address
            </label>
            <div>rahul.bhide@gmail.com</div>
            <div className="mt-3">
              <input
                type="checkbox"
                className="form-check-input custom-check"
                id="exampleCheck1"
                onChange={handleChangeCheckbox}
              />
              <label
                className="form-check-label ms-2 text-black"
                for="exampleCheck1"
              >
                I agree to the Terms of Service and Privacy Policy
              </label>
            </div>
          </div>

          <button className="btn btn-primary w-100" disabled={!isChecked}>
            Confirm OTP
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailMobile;
