import React, { useRef, useState } from "react";

const OtpInputs = ({ auth, setAuth }) => {
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const [otp, setOTP] = useState("");
  let selfotp = "123456";

  const handleChange = (index, e) => {
    const value = e.target.value;

    setOTP((prevOTP) => {
      let updatedOTP = prevOTP;
      updatedOTP =
        updatedOTP.substring(0, index) +
        value +
        updatedOTP.substring(index + 1);
      return updatedOTP;
    });

    const nextIndex = index + 1;
    const prevIndex = index - 1;

    if (value && nextIndex < inputRefs.length) {
      inputRefs[nextIndex].current.focus();
    }

    if (!value && prevIndex >= 0) {
      inputRefs[prevIndex].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      if (inputRefs[index].current.value === "" && index > 0) {
        inputRefs[index - 1].current.focus();
      } else if (index > 0) {
        inputRefs[index].current.value = "";
        inputRefs[index - 1].current.focus();
      }
    }
  };

  return (
    <div className="row mb-4 otp-inputs">
      {[0, 1, 2, 3, 4, 5].map((item, index) => (
        <div className="col px-1 px-md-2" key={index}>
          <input
            className="form-control"
            ref={inputRefs[index]}
            type="text"
            maxLength="1"
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        </div>
      ))}
    </div>
  );
};

export default OtpInputs;
