import React from "react";
import "./loginForm.css";
import ResendOtp from "../resendOTP/ResendOtp";
import OtpInputs from "../otpInputs/OtpInputs";

const EnterOtp = () => {
  return (
    <div className="login-form-wrapper">
      <div className="login-form">
        <h4 className="welcome-text">Welcome to Boozno</h4>
        <h3 className="mb-3">Enter OTP</h3>
        <p className="description mb-5">OTP sent on your registered email id</p>
        <div className="form-input-wrapper">
          <OtpInputs />
          <button className="btn btn-primary w-100">Confirm OTP</button>

          <ResendOtp />
        </div>
      </div>
    </div>
  );
};

export default EnterOtp;
