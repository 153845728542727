import React from "react";
import "./LoginPage.css";
import LoginDesign from "../loginDesign/LoginDesign";
import InactiveAccountModal from "../../common/modal/InactiveAccountModal";
import SignInForm from "../loginForm/SignInForm";
import EnterOtp from "../loginForm/EnterOtp";
import VerifyEmailMobile from "../loginForm/VerifyEmailMobile";
import EnterOtpMobileEmail from "../loginForm/EnterOtpMobileEmail";

const LoginPage = ({ FormDetails }) => {
  return (
    <div className="login-page-container">
      <div className="row h-100 m-0">
        <div className="col-md-5 p-2 p-md-4">
          {/* this component will render the left side design in signup page */}
          <LoginDesign />
        </div>
        <div className="col-md-7">
          {/* this component will render the login form in signup page */}
          {FormDetails.signup ? (
            <SignInForm />
          ) : FormDetails.otp ? (
            <EnterOtp />
          ) : FormDetails.verify ? (
            <VerifyEmailMobile />
          ) : (
            <EnterOtpMobileEmail />
          )}
        </div>
      </div>
      <InactiveAccountModal />
    </div>
  );
};

export default LoginPage;
