import React from "react";
import "./loginForm.css";
import OtpInputs from "../otpInputs/OtpInputs";
import ResendOtp from "../resendOTP/ResendOtp";

const EnterOtpMobileEmail = () => {
  return (
    <div className="login-form-wrapper">
      <div className="login-form">
        <h4 className="welcome-text">Welcome to Boozno</h4>
        <h3 className="mb-3">Enter OTP</h3>
        <p className="description mb-5">
          OTP sent on your email and mobile number
        </p>
        <div className="form-input-wrapper">
          <div>
            <label htmlFor="" className="form-label mb-3">
              Email OTP sent on rahu****de@gmail.com
            </label>
            <OtpInputs />
            <label htmlFor="" className="form-label mt-2 mb-3">
              Mobile OTP sent on 8454****98
            </label>
            <OtpInputs />
          </div>

          <button className="btn btn-primary w-100">Confirm OTP</button>

          <ResendOtp />
        </div>
      </div>
    </div>
  );
};

export default EnterOtpMobileEmail;
