import { Route, Routes } from "react-router-dom";
import { createContext, useState } from "react";
import LoginPage from "./components/login/loginPage/LoginPage";

function App() {
  const SignUpFrom = {
    formTitle: "Sign In to Continue",
    formDescription: " Enter your details to proceed further",
    signup: true,
    otp: false,
    verify: false,
  };
  const SendOtpFrom = {
    formTitle: "Enter OTP",
    formDescription: "OTP sent on your registered email id",
    otp: true,
    otpemailmobile: false,
    verify: false,
  };
  const VerifyEmailMobile = {
    formTitle: "Verify your Email & Mobile no.",
    formDescription:
      "Contact admin to change your mobile number or email address.",
    otp: false,
    otpemailmobile: false,
    verify: true,
  };
  const EnterOtpMobileEmail = {
    formTitle: "Enter OTP",
    formDescription: "OTP sent on your email and mobile number",
    otp: false,
    verify: false,
    otpemailmobile: true,
  };
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage FormDetails={SignUpFrom} />} />
        <Route
          path="/sendotp"
          element={<LoginPage FormDetails={SendOtpFrom} />}
        />
        <Route
          path="/verifyemailmobile"
          element={<LoginPage FormDetails={VerifyEmailMobile} />}
        />
        <Route
          path="/sendotpemailmobile"
          element={<LoginPage FormDetails={EnterOtpMobileEmail} />}
        />
      </Routes>
    </div>
  );
}

export default App;
