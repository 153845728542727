import React from "react";
import "./style.css";
import LockImg from "../../../assets/img/lock-img.png";

const InactiveAccountModal = () => {
  return (
    <div>
      {/* <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#inactiveAccount"
      >
        Launch demo modal
      </button> */}

      <div
        className="modal fade"
        id="inactiveAccount"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* <div className="modal-header border border-0">
              <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="modal-body pt-4">
              <div className="inactive-account-modal-wrapper">
                <img src={LockImg} alt="" />
                <h4 className="fw-bold">Your Account is In-active</h4>
                <p className="description mb-3">
                  OTP sent on your registered email id
                </p>
              </div>
            </div>
            <div className="modal-footer border border-0 pt-0 pb-3 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-sm px-5"
                data-bs-dismiss="modal"
              >
                Okay
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InactiveAccountModal;
