import React from "react";
import "./LoginDesign.css";
import { BooznoLogo } from "../../../assets/img/svg";

const LoginDesign = () => {
  return (
    <div className="login-design-wrapper">
      <div className="logo-wrapper">
        <BooznoLogo />
      </div>
      <div className="login-design-title">
        Turn Leads <br /> Into <br />
        Customers <br />
        Easier
      </div>
    </div>
  );
};

export default LoginDesign;
