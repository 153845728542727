import React, { useState } from "react";
import "./loginForm.css";
import { useNavigate } from "react-router-dom";
import InactiveAccountModal from "../../common/modal/InactiveAccountModal";

const SignInForm = () => {
  let nav = useNavigate();
  const [inputEmail, setInputEmail] = useState(""); // this will take the input value
  const [auth, setAuth] = useState(true); // for login authentication
  const authEmail = "shaad@gmail.com"; // dummy email

  // signup input change function
  const handleChange = (event) => {
    setAuth(true);
    setInputEmail(event.target.value);
  };

  // button click function
  const handleClick = () => {
    if (inputEmail === authEmail) {
      nav("/sendotp");
      setAuth(true);
    } else {
      setAuth(false);
    }
  };

  return (
    <div className="login-form-wrapper">
      <div className="login-form">
        <h4 className="welcome-text">Welcome to Boozno</h4>
        <h3 className="mb-3">Sign In to Continue</h3>
        <p className="description mb-5">
          Enter your details to proceed further
        </p>
        <div className="form-input-wrapper">
          <div>
            <div>
              <label
                htmlFor=""
                className={`${auth ? "form-label" : "form-label text-red"}`}
              >
                Email Address or Mobile Number *
              </label>
              <div className="mb-4">
                <input
                  type="text"
                  className={`${
                    auth
                      ? "form-control input-one-line"
                      : "form-control border-bottom-red"
                  }`}
                  placeholder="..."
                  onChange={handleChange}
                />
                {auth ? (
                  <></>
                ) : (
                  <div>
                    <p className="error-msg mb-3">Email not registered</p>
                    <p className="sm-text">
                      The entered email is is not registered with Boozno.com.
                      Please contact admin.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <button
            className="btn btn-primary w-100"
            data-bs-toggle="modal"
            data-bs-target="#inactiveAccount"
            onClick={handleClick}
          >
            Send OTP
          </button>
          <InactiveAccountModal />
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
