import React, { useState, useEffect } from "react";
import "./ResendOtp.css";

const ResendOtp = () => {
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      // Timer has reached 0, perform any action here if needed
      // For now, we'll just stop the timer
      clearInterval(timer);
    }
  }, [timer]);
  return (
    <div className="resend-otp-wrapper">
      {timer > 0 ? (
        <span>Resend OTP in 00:{timer < 10 ? `0${timer}` : timer}</span>
      ) : (
        <button className="resend-otp-btn" onClick={() => setTimer(30)}>Resend OTP</button>
      )}
    </div>
  );
};

export default ResendOtp;
